import React from "react"
import { graphql } from "gatsby"
import Head from "../components/Head/Head"
import BackgroundImage from "gatsby-background-image"
import Logo from "../components/Logo"
import styled from "styled-components"

const Home = ({ data }) => {
  const renderedImage = [
    data.image.childImageSharp.fluid,
    `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5))`,
  ].reverse()

  const HomepageStyles = styled.div`
    max-width: 900px;
    margin: 0 auto;
    font-size: clamp(3rem, 3vw, 6rem);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 12rem;
  `
  return (
    <>
      <Head title="UI Team Home" />
      <BackgroundImage
        Tag="section"
        fluid={renderedImage}
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "calc(100vh - 212px)",
          width: "100%",
        }}
      >
        <HomepageStyles>
          <Logo />
        </HomepageStyles>
      </BackgroundImage>
    </>
  )
}

export default Home

export const PageQuery = graphql`
  query HomeQuery {
    image: file(relativePath: { eq: "comcast.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
